import * as THREE from 'three';
import Experience from './Experience';

//Need to do garbage collection! Important note for later.

export default class SpatialVideo {
    constructor() {
      this.experience = new Experience();
      this.scene = this.experience.scene;
      this.videoElement = this.experience.video;
      this.videoTexture = new THREE.VideoTexture(this.videoElement)
      this.videoTexture.colorSpace = THREE.SRGBColorSpace;
      this.aspectRatio = this.videoTexture.image.videoWidth * 0.5 / this.videoTexture.image.videoHeight;

      if (this.experience.isXRActive()) {
        for (let eye of [false, true]) {
            const mesh = this.createMesh(!eye);
            if (mesh) {
                mesh.layers.set(eye ? 1 : 2);
                this.scene.add(mesh);
            }
        }
      } else {
          const mesh = this.createMesh();
          if (mesh) {
            mesh.layers.set(0);
            this.scene.add(mesh);
          }
      }
    }
    createMesh(rightEye = false) {
      const geometry = new THREE.PlaneGeometry(this.videoTexture.image.videoWidth * 0.5, this.videoTexture.image.videoHeight, 60, 40);
      const uvs = geometry.attributes.uv.array;
      // let material;
      // if (rightEye) material = new THREE.MeshBasicMaterial({ map: this.videoTexture, side: THREE.DoubleSide});
      // else material = new THREE.MeshBasicMaterial({ color: new THREE.Color(0xff0000), side: THREE.DoubleSide });
      for (let j = 0; j < uvs.length; j += 2) {
        uvs[j] *= 0.5;
        if (rightEye) uvs[j] += 0.5;
      }
      geometry.attributes.uv.needsUpdate = true;
      const material = new THREE.MeshBasicMaterial({ map: this.videoTexture, side: THREE.DoubleSide });
      const mesh = new THREE.Mesh(geometry, material);
      //mesh.rotateY(Math.PI) 
      mesh.scale.multiplyScalar(1/200)
      mesh.position.set(0, 0, (rightEye) ? -5 : -5.01) 
  
      return mesh; 
    }
    update() {
        this.videoTexture.update();
    }
}