import * as THREE from "three";
import Sizes from "./Utils/Sizes.js";
import Camera from "./Camera.js";
import Renderer from "./Renderer.js";
import VideoPlayer from "./VideoPlayer.js";
import SpatialVideo from "./SpatialVideo.js";
import Debug from "./Utils/Debug.js";
import { VRButton } from "three/examples/jsm/webxr/VRButton.js";
let instance = null;

export default class Experience {
  constructor(canvas) {
    if (instance) {
      return instance;
    }
    instance = this;
    window.experience = this;

    this.debug = new Debug();
    this.canvas = canvas;
    this.scene = new THREE.Scene();
    this.sizes = new Sizes();
    this.camera = new Camera();
    this.renderer = new Renderer();
    this.video = document.getElementById("video");
    this.videoPlayer = new VideoPlayer();
    this.spatialVideo = new SpatialVideo();

    // GROUND PLANE
    this.groundPlane = new THREE.GridHelper( 150, 50 );
    this.groundPlane.translateY(-10);
    this.scene.add( this.groundPlane );

    // VRButton
    this.renderer.instance.xr.enabled = true;
    document.body.appendChild(VRButton.createButton(this.renderer.instance));

    // tick
    this.renderer.instance.setAnimationLoop(() => {
      this.camera.update();
      this.renderer.update();
      this.spatialVideo.update();
    });

    document.addEventListener('keydown', (e) => {
      if (e.code === 'Space') {
        console.log("Space Key Pressed")
        this.videoPlayer.togglePlay()
      }
    });

    this.renderer.instance.xr.addEventListener('sessionstart', () => {
      this.spatialVideo = new SpatialVideo();
      this.videoPlayer.togglePlay();
      this.camera.reset();
      // renderer.xr.setAnimationLoop(() => {
      //   renderer.clear();
      //   renderer.render(scene, camera);
        
      //   camera.layers.set(1);
      //   renderer.render(scene, camera);
      //   camera.layers.set(2);
      // });
    })

    this.renderer.instance.xr.addEventListener('sessionend', () => {
      this.spatialVideo = new SpatialVideo();
      this.videoPlayer.togglePlay();
      this.camera.reset();
    })

    this.sizes.on("resize", () => {
      this.camera.resize();
      this.renderer.resize();
    });
  }
  isXRActive() {
    return this.renderer.instance.xr.isPresenting;
  }
}
